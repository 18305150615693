var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-batch-payments"},[_c('error',{attrs:{"err":_vm.error},on:{"update:err":function($event){_vm.error=$event}}}),_c('advanced-data-table',{ref:"table",attrs:{"search":false,"show-limit":false,"headers":_vm.headers,"items":_vm.royalties,"paginate":false,"loadingGlobal":_vm.loading,"sticky-header":"60vh","total-items":_vm.royaltiesRaw.length},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(completed)",fn:function({ item, field: { key } }){return [_c('b-checkbox',{model:{value:(item[key]),callback:function ($$v) {_vm.$set(item, key, $$v)},expression:"item[key]"}})]}},{key:"cell(adjustment)",fn:function({ item }){return [_c('b-input',{attrs:{"type":"number","placeholder":_vm.$t('adjustment-amount'),"size":"sm"},model:{value:(item.adjustment_amount),callback:function ($$v) {_vm.$set(item, "adjustment_amount", $$v)},expression:"item.adjustment_amount"}})]}},{key:"cell(notes)",fn:function({ item }){return [_c('b-input',{attrs:{"type":"text","placeholder":_vm.$t('notes'),"size":"sm"},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}})]}},{key:"cell(amount)",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.formatCurrency( item.ra + parseFloat(item.adjustment_amount || 0), _vm.primaryCurrency ))+" ")]}},{key:"cell(commission)",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.formatCurrency(parseFloat(item.commission || 0), _vm.primaryCurrency))+" ")]}},{key:"cell(payment)",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.formatCurrency( parseFloat(item.adjustment_amount || 0) + (item.ra - item.commission), _vm.primaryCurrency ))+" ")]}},{key:"cell(client)",fn:function({item}){return [_c('router-link',{attrs:{"to":{
          name: 'AddPayment',
          query: { client_id: item.user_id }
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"filters",fn:function(){return [_c('adt-filter',{attrs:{"label":_vm.$t('payment-method')}},[_c('b-select',{attrs:{"size":"sm","placeholder":_vm.$t('payment-method'),"options":_vm.addAllOption(_vm.paymentMethodsDropdown, 'select-method'),"disabled":!!_vm.completed.length},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1),(_vm.paymentMethod)?_c('adt-filter',{attrs:{"label":_vm.$t('enrolled-in-self-billing')}},[_c('b-select',{attrs:{"size":"sm","placeholder":_vm.$t('enrolled-in-self-billing'),"options":_vm.addAllOption(_vm.yesNo),"disabled":!!_vm.completed.length},model:{value:(_vm.isEnrolledInSelfBilling),callback:function ($$v) {_vm.isEnrolledInSelfBilling=$$v},expression:"isEnrolledInSelfBilling"}})],1):_vm._e(),(_vm.paymentMethod)?_c('adt-filter',{attrs:{"label":_vm.$t('outstanding')}},[_c('b-select',{attrs:{"size":"sm","placeholder":_vm.$t('outstanding'),"options":_vm.addAllOption(_vm.yesNo),"disabled":!!_vm.completed.length},model:{value:(_vm.isOutstanding),callback:function ($$v) {_vm.isOutstanding=$$v},expression:"isOutstanding"}})],1):_vm._e(),(_vm.paymentMethod)?_c('adt-filter',{attrs:{"label":_vm.$t('payment-frequencies')}},[_c('b-dropdown',{attrs:{"disabled":!!_vm.completed.length,"variant":"outline-primary","text":_vm.$t(_vm.frequencies.length ? 'no-selected' : 'select-frequencies', {
              count: _vm.frequencies.length
            })}},[_c('b-dropdown-text',[_c('b-form-checkbox-group',{attrs:{"switches":"","stacked":""},model:{value:(_vm.frequencies),callback:function ($$v) {_vm.frequencies=$$v},expression:"frequencies"}},_vm._l((_vm.paymentFrequencies),function(f){return _c('b-checkbox',{key:f.id,attrs:{"value":f.id}},[_vm._v(_vm._s(f.name))])}),1)],1)],1)],1):_vm._e(),(_vm.paymentMethod)?_c('adt-filter',{staticClass:"mt-2"},[_c('b-checkbox',{attrs:{"disabled":!!_vm.completed.length},model:{value:(_vm.respectMinimumPayment),callback:function ($$v) {_vm.respectMinimumPayment=$$v},expression:"respectMinimumPayment"}},[_vm._v(_vm._s(_vm.$t("respect-minimum-payment")))])],1):_vm._e(),(_vm.paymentMethod)?_c('adt-filter',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.get}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.loading)?_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")]):_c('span',[_vm._v("Fetch")])],1)],1):_vm._e()]},proxy:true},{key:"actions",fn:function({item}){return [_c('ebp-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.$t('view-royalties')),expression:"$t('view-royalties')",modifiers:{"top-center":true}}],staticClass:"ml-1",attrs:{"outlined":"","variant":"success","size":"sm","to":{
          name: 'ViewRoyalties',
          query: { user_id: item.user_id, status: 'received' }
        },"target":"_blank"}},[_c('i',{staticClass:"bx bxs-show"})]),_c('ebp-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(_vm.$t('view-payment-details')),expression:"$t('view-payment-details')",modifiers:{"top-center":true}}],staticClass:"ml-1",attrs:{"outlined":"","variant":"dark","size":"sm"},on:{"click":function($event){return _vm.triggerShowPaymentDetails(item)}}},[_c('i',{staticClass:"bx bx-id-card"})]),(
          _vm.paymentMethod === _vm.find(_vm.paymentMethods, p => p.code === 'paypal').id
        )?_c('ebp-button',{staticClass:"ml-1",attrs:{"size":"sm","loading":_vm.copying === item.user_id},on:{"click":function($event){return _vm.copy(item)}}},[_c('i',{staticClass:"mdi mdi-content-copy"}),_vm._v(" "+_vm._s(_vm.$t("copy-paypal-address"))+" ")]):_vm._e(),_c('ebp-button',{staticClass:"ml-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deletePreferences(item)}}},[_c('i',{staticClass:"mdi mdi-delete"},[_vm._v(_vm._s(_vm.$t("delete-preferences")))])])]}},(_vm.completed.length)?{key:"footer",fn:function(){return [_c('div',{staticClass:"floating-control"},[_c('div',{staticClass:"wrapper"},[_c('span',[_vm._v("Batch Payment Total: "+_vm._s(_vm.formatCurrency(_vm.total, _vm.primaryCurrency)))]),_c('ebp-button',{attrs:{"variant":"success","loading":_vm.adding},on:{"click":_vm.add}},[_vm._v(_vm._s(_vm.$t("complete-payments")))])],1)])]},proxy:true}:null],null,true)}),_c('ebp-modal',{attrs:{"open":_vm.showPaymentDetails,"tabs":_vm.paymentDetailsTabs},on:{"update:open":function($event){_vm.showPaymentDetails=$event}},scopedSlots:_vm._u([{key:"payment-details",fn:function(){return [(_vm.loadingPaymentDetails)?_c('spinner'):(_vm.paymentDetails)?[_c('payment-details',{attrs:{"preferences":_vm.paymentDetails,"users":[_vm.showPaymentDetails]}})]:[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("validation-errors.no-payment-details"))+" ")])]]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }