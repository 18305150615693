<template>
  <div class="add-batch-payments">
    <error :err.sync="error" />
    <advanced-data-table
      :search="false"
      :show-limit="false"
      :headers="headers"
      :items="royalties"
      :paginate="false"
      :loadingGlobal="loading"
      sticky-header="60vh"
      @row-clicked="rowClicked"
      ref="table"
      :total-items="royaltiesRaw.length"
    >
      <template v-slot:cell(completed)="{ item, field: { key } }">
        <b-checkbox v-model="item[key]"></b-checkbox>
      </template>

      <template v-slot:cell(adjustment)="{ item }">
        <b-input
          v-model="item.adjustment_amount"
          type="number"
          :placeholder="$t('adjustment-amount')"
          size="sm"
        />
      </template>

      <template v-slot:cell(notes)="{ item }">
        <b-input
          v-model="item.notes"
          type="text"
          :placeholder="$t('notes')"
          size="sm"
        />
      </template>

      <template #cell(amount)="{item}">
        {{
          formatCurrency(
            item.ra + parseFloat(item.adjustment_amount || 0),
            primaryCurrency
          )
        }}
      </template>

      <template #cell(commission)="{item}">
        {{ formatCurrency(parseFloat(item.commission || 0), primaryCurrency) }}
      </template>

      <template #cell(payment)="{item}">
        {{
          formatCurrency(
            parseFloat(item.adjustment_amount || 0) +
              (item.ra - item.commission),
            primaryCurrency
          )
        }}
      </template>

      <template #cell(client)="{item}">
        <router-link
          :to="{
            name: 'AddPayment',
            query: { client_id: item.user_id }
          }"
          target="_blank"
        >
          {{ item.name }}
        </router-link>
      </template>

      <template #filters>
        <!-- Method filter -->
        <adt-filter :label="$t('payment-method')">
          <b-select
            size="sm"
            v-model="paymentMethod"
            :placeholder="$t('payment-method')"
            :options="addAllOption(paymentMethodsDropdown, 'select-method')"
            :disabled="!!completed.length"
          />
        </adt-filter>

        <!-- Is Enrolled filter -->
        <adt-filter
          :label="$t('enrolled-in-self-billing')"
          v-if="paymentMethod"
        >
          <b-select
            size="sm"
            v-model="isEnrolledInSelfBilling"
            :placeholder="$t('enrolled-in-self-billing')"
            :options="addAllOption(yesNo)"
            :disabled="!!completed.length"
          />
        </adt-filter>

        <!-- Is Outstanding filter -->
        <adt-filter :label="$t('outstanding')" v-if="paymentMethod">
          <b-select
            size="sm"
            v-model="isOutstanding"
            :placeholder="$t('outstanding')"
            :options="addAllOption(yesNo)"
            :disabled="!!completed.length"
          />
        </adt-filter>

        <!-- Payment frequency filter -->
        <adt-filter :label="$t('payment-frequencies')" v-if="paymentMethod">
          <b-dropdown
            :disabled="!!completed.length"
            variant="outline-primary"
            :text="
              $t(frequencies.length ? 'no-selected' : 'select-frequencies', {
                count: frequencies.length
              })
            "
          >
            <b-dropdown-text>
              <b-form-checkbox-group switches stacked v-model="frequencies">
                <b-checkbox
                  v-for="f in paymentFrequencies"
                  :key="f.id"
                  :value="f.id"
                  >{{ f.name }}</b-checkbox
                >
              </b-form-checkbox-group>
            </b-dropdown-text>
          </b-dropdown>
        </adt-filter>

        <!-- Minimum payment threshold filter -->
        <adt-filter v-if="paymentMethod" class="mt-2">
          <b-checkbox
            :disabled="!!completed.length"
            v-model="respectMinimumPayment"
            >{{ $t("respect-minimum-payment") }}</b-checkbox
          >
        </adt-filter>

        <!-- Minimum payment threshold filter -->
        <adt-filter v-if="paymentMethod">
          <b-button variant="primary" @click="get" :disabled="loading">
            <b-spinner v-if="loading" small></b-spinner>
            <span class="sr-only" v-if="loading">Loading...</span>
            <span v-else>Fetch</span>
          </b-button>
        </adt-filter>
      </template>

      <template #actions="{item}">
        <ebp-button
          outlined
          variant="success"
          size="sm"
          class="ml-1"
          :to="{
            name: 'ViewRoyalties',
            query: { user_id: item.user_id, status: 'received' }
          }"
          target="_blank"
          v-tooltip.top-center="$t('view-royalties')"
        >
          <i class="bx bxs-show"></i>
        </ebp-button>
        <ebp-button
          outlined
          variant="dark"
          size="sm"
          class="ml-1"
          @click="triggerShowPaymentDetails(item)"
          v-tooltip.top-center="$t('view-payment-details')"
        >
          <i class="bx bx-id-card"></i>
        </ebp-button>
        <ebp-button
          size="sm"
          class="ml-1"
          @click="copy(item)"
          :loading="copying === item.user_id"
          v-if="
            paymentMethod === find(paymentMethods, p => p.code === 'paypal').id
          "
        >
          <i class="mdi mdi-content-copy"></i>
          {{ $t("copy-paypal-address") }}
        </ebp-button>
        <ebp-button
          variant="danger"
          size="sm"
          class="ml-1"
          @click="deletePreferences(item)"
        >
          <i class="mdi mdi-delete">{{
            $t("delete-preferences")
          }}</i></ebp-button
        >
      </template>

      <template #footer v-if="completed.length">
        <div class="floating-control">
          <div class="wrapper">
            <span
              >Batch Payment Total:
              {{ formatCurrency(total, primaryCurrency) }}</span
            >
            <ebp-button variant="success" :loading="adding" @click="add">{{
              $t("complete-payments")
            }}</ebp-button>
          </div>
        </div>
      </template>
    </advanced-data-table>

    <!-- Payment details modal -->
    <ebp-modal :open.sync="showPaymentDetails" :tabs="paymentDetailsTabs">
      <template #payment-details>
        <spinner v-if="loadingPaymentDetails" />
        <template v-else-if="paymentDetails">
          <payment-details
            :preferences="paymentDetails"
            :users="[showPaymentDetails]"
          />
        </template>

        <template v-else>
          <p class="text-center">
            {{ $t("validation-errors.no-payment-details") }}
          </p>
        </template>
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import PaymentDetails from "@/components/PaymentDetails";
import AdtFilter from "@/components/core/adt-filter";
import { sum, find, map, groupBy, orderBy } from "lodash-es";
import { mapState } from "vuex";

export default {
  name: "add-batch-payments",
  components: {
    AdtFilter,
    AdvancedDataTable,
    PaymentDetails
  },
  data() {
    return {
      headers: [
        "completed",
        "adjustment",
        "notes",
        { label: "subtotal-2", key: "amount" },
        "commission",
        "payment",
        "client"
      ],
      client_id: null,
      loading: false,
      adding: false,
      royaltiesRaw: [],
      royalties: [],
      error: null,
      adjustment_amount: null,
      paymentMethod: null,
      showPaymentDetails: false,
      paymentDetails: null,
      paymentDetailsTabs: ["payment-details"],
      loadingPaymentDetails: false,
      isEnrolledInSelfBilling: null,
      copying: false,
      frequencies: [],
      respectMinimumPayment: true,
      isOutstanding: null
    };
  },
  mounted() {
    this.$store.dispatch("currencies/get");
    this.$store.dispatch("ancillaries/getPaymentMethods");
    this.$store.dispatch("ancillaries/getPaymentFrequencies");
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState("currencies", ["currencies"]),
    ...mapState("ancillaries", ["paymentMethods", "paymentFrequencies"]),
    total() {
      return sum(
        this.completed.map(
          item =>
            parseFloat(item.adjustment_amount || 0) +
            (item.ra - (item.ra / 100) * item.commission)
        )
      );
    },
    totalCommission() {
      return sum(
        this.completed.map(record => (record.ra / 100) * record.commission)
      );
    },
    primaryCurrency() {
      const currency = find(this.currencies, c => c.is_primary);
      return currency ? currency.code : "GBP";
    },
    completed() {
      return this.royaltiesRaw.filter(i => i.completed);
    },
    paymentMethodsDropdown() {
      const paymentMethods = this.paymentMethods.map(m => ({
        text: this.$t(m.name),
        value: m.id
      }));

      paymentMethods.unshift(
        { text: "All", value: 100 },
        { text: "Without payments", value: 99 }
      );

      return paymentMethods;
    }
  },
  methods: {
    find,
    async deletePreferences(item) {
      if (confirm(this.$t("remove-preferences"))) {
        await this.$store.dispatch(
          "userPreferences/deletePreferencesById",
          item.user_id
        );
      }
    },
    async copy(item) {
      this.copying = item.user_id;
      await this.getPaymentDetails(item.user_id);
      await navigator.clipboard.writeText(
        this.paymentDetails.payment_method_details.paypal_address
      );
      this.$toast.success(this.$t("paypal-copied"));
      this.copying = false;
    },
    rowClicked(item) {
      if (item.completed) {
        this.$set(item, "completed", false);
      } else {
        this.$set(item, "completed", true);
      }
    },
    handleScroll() {
      const scrollable = document.querySelector(".b-table-sticky-header");

      if (!scrollable) {
        return;
      }
      const scrollHeight = scrollable.scrollHeight - scrollable.clientHeight;
      const scrollTop = scrollable.scrollTop;

      if (scrollTop === scrollHeight) {
        this.royalties = this.royalties.concat(
          this.royaltiesRaw.slice(
            this.royalties.length,
            this.royalties.length + 35
          )
        );

        this.previousScrollHeight =
          scrollable.scrollHeight - scrollable.clientHeight;
      }
    },
    async add() {
      this.error = null;
      if (this.total < 0) {
        this.error = "validation-errors.amount-less-than-zero";
      } else if (!this.paymentMethod) {
        this.error = "validation-errors.payment-method-required";
      } else {
        this.adding = true;

        try {
          await this.$store.dispatch("outboundPayments/createBatch", {
            completed: this.completed
          });
          this.royaltiesRaw = this.royaltiesRaw
            .filter(r => !this.completed.map(c => c.id).includes(r.id))
            .map(r => ({ ...r, completed: false }));
          this.royalties = this.royaltiesRaw.slice(0, 35);
        } catch (err) {
          console.error(err);
        }

        this.adding = false;
      }
    },
    async get() {
      this.error = null;
      this.royaltiesRaw = [];
      this.royalties = [];

      const { paymentMethod } = this;
      if (!paymentMethod) {
        this.error = this.$t("validation-errors.required-all");
      } else {
        this.loading = true;

        try {
          const res = await this.$store.dispatch("royalties/queryByMethod", {
            paymentMethod,
            isEnrolledInSelfBilling: this.isEnrolledInSelfBilling,
            status: "received",
            frequencies: this.frequencies.join(","),
            respect_minimum_payment: this.respectMinimumPayment,
            is_outstanding: this.isOutstanding
          });

          const rows = map(
            groupBy(res.data, r => r.user_id),
            royalties => {
              const royalty = royalties[0];
              royalty.count = 1;
              return royalty;
            }
          );

          this.royaltiesRaw = rows;
          this.royalties = orderBy(rows, "ra", "desc");
        } catch (err) {
          console.error(err);
        }

        this.loading = false;
      }
    },
    async getPaymentDetails(id) {
      this.loadingPaymentDetails = true;
      try {
        const res = await this.$store.dispatch("userPreferences/get", id);
        this.paymentDetails = res.data;
      } catch (err) {
        console.error(err);
      }
      this.loadingPaymentDetails = false;
    },
    triggerShowPaymentDetails(user) {
      this.showPaymentDetails = {
        id: user.user_id,
        name: user.name
      };
    }
  },
  watch: {
    showPaymentDetails(user) {
      if (user) {
        this.getPaymentDetails(user.id);
      }
    }
  }
};
</script>

<style lang="scss">
.add-batch-payments {
  .dropdown-toggle {
    padding: 0.36rem 0;
  }

  .checkbox {
    background: white;
    border: 2px solid $primary;
    border-radius: 5px;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.checked {
      background: $primary;
    }
  }

  .floating-control {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      margin: 0;
    }

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #000;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 10px;
      * > {
        flex: 1;
      }

      span {
        margin-right: 10px;
      }

      .input-cont {
        display: flex;
      }
    }
  }

  .sweet-content {
    padding: 10px !important;
  }

  .list-group-item {
    border: 0;
  }
}
</style>
